.parent {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 15px;
}

.columnOne {
    /* min-height: 100vh; */
    /* padding-top: 50px; */
    font-size: 15px;
}

/* .columnTwo {
   min-height: 100vh; 
   background-color: red;
} */

.columnOneElement {
    z-index: 1;
    width: 100%;
}

.columnTwoElement {
    z-index: 1;
    width: 100%;
}

#headingContainer {
    width: 100%;
    margin-bottom: 40px;
}

#heading {
    color: white;
    letter-spacing: 2px;
}

#subHeadingContainer {
    width: 60%;
    margin-bottom: 40px;
}

#subHeading {
    color: white;
}

@keyframes infoanim {
    0% {opacity: 0;}
    100% { opacity: 1; }
}

@-webkit-keyframes infoanim {
    0% {opacity: 0;}
    100% { opacity: 1; }
}

@-moz-keyframes infoanim {
    0% {opacity: 0;}
    100% { opacity: 1; }
}

@-o-keyframes infoanim {
    0% {opacity: 0;}
    100% { opacity: 1; }
}

.info {
    font-size: 12px;
    animation: infoanim;
    -webkit-animation: infoanim;
    -moz-animation: infoanim;
    -o-animation: infoanim;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
}

.infoPara {
    font-size: 1em;
    color: white;
}

#arrowContainer {
    display: flex;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 70px;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 1;
    width: 100%;
    
}

#arrowBox {
    animation: arrowBounce 3s infinite;
    -o-animation: arrowBounce 3s infinite;
    -webkit-animation: arrowBounce 3s infinite;
    -moz-animation: arrowBounce 3s infinite;
}

@keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-webkit-keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-moz-keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-o-keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

#formHeading {
    color: white;
    letter-spacing: 3px;
    margin-bottom: 0px;
}

.form {
    width: 70%;
}

.formLabel {
    color: white;
}

#formButton {
    margin-top: 20px;
}

.logoContainer {
    border-radius: 45px;
    /* align-self: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    width: 50px;
    height: 50px;
}

#backDrop {
    position: absolute;
    bottom: 0; 
    top: 0; left: 0;
    right: 0; 
    background-color: rgba(0, 0, 0, 0.5)
}

@media only screen and (max-width: 800px) {
    #headingContainer {
        text-align: center;
    }

    #subHeadingContainer {
        width: 100%;
        text-align: center;
    }
}

@media only screen and (min-width: 555px) {
    #arrowContainer {
        display: none;
    }
}

@media only screen and (max-width: 414px) {
    #heading {
        font-size: 2em;
    }

    #subHeading {
        font-size: 1.3em;
    }
}

@media only screen and (max-height: 414px) {
    #heading {
        font-size: 2em;
    }

    #subHeading {
        font-size: 1.3em;
    }
}

/* @media only screen and (max-height: 500px) and (max-width: 750px) {
    .fg {
        height: 35px;
    }

    .form {
        font-size: 0.8em;
    }

    .formLabel {
        margin-bottom: 0px;
    }

    #formButton {
        height: 1.8em;
        padding: 0px;
    }

    #formHeading {
        margin-bottom: 5px;
    }

    .form input {
        font-size: 0.8em;
    }

    .form select {
        font-size: 0.8em;
    }
} */

@media only screen and (max-height: 600px) {
    .infoPara {
        font-size: 0.8em;
    }
}

#termsCon {
    margin-top: 10px;
    width: 60%;
    /* background-color: palegreen; */
}

@media only screen and (max-width: 500px) {
    .form {
        width: 90%;
        /* font-size: 0.8em; */
    }

    .form input {
        font-size: 0.8em;
        height: 35px;
    
    }
    
    .form select {
        font-size: 0.8em;
        height: 35px;
    }

    #termsCon {
        width: 80%;
    }
}

.formHeadingContainer { 
    display: flex; 
    justify-content: center;
    margin-bottom: 10px;
    align-items: center 
}

.loader {
    margin-left: 10px;
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    border-top: 1px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    -o-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

.termsText {
    margin: 0px;
    padding: 0px;
    color: white;
    text-align: center;
    font-weight: 300;
    font-size: 11px;
}

.buttonLink {
    background: none!important;
    border: none;
    padding: 0!important;
    font-size: 11px;
    color: #0275d8;
    cursor: pointer;
}

