.parent {
    font-size: 15px;
    height: 300px;
    display: flex;
}

.container {
    display: 'flex';
    flex: 1;
    align-items: 'center'; 
    justify-content: 'center'; 
    width: '100%'; 
    height: '100%'; 
}

.containerBox {
    margin-top: 10px;
    overflow: hidden; 
    height: 90%;
    background-color: white; 
    border-radius: 8px; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) 
}

.imgContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    width: 50%;
    height: 0.5px;
    background-color: grey;
    align-self: center;
}

.contentContainer {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 80%;
    text-align: center;
    font-size: 0.9rem;
}