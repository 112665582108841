#socialIconsCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sIcon {
  margin-top: 10px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.footerText {
  margin: 0px;
  padding: 0px;
  color: white;
  text-align: center;
  font-weight: 100;
  font-size: 11px;
}

#footerTextAtt {
  margin: 0px;
  padding: 0px;
  color: white;
  text-align: center;
  font-weight: 100;
  font-size: 8px;
}

.footerCon {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 180px;
  /* background-color: #01071a; */
  background-color: #01122f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  flex-direction: column;
}

.buttonLink {
  background: none!important;
  border: none;
  padding: 0!important;
  font-size: 11px;
  color: #0275d8;
  cursor: pointer;
}
