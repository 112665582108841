.notifcontainer {
    display: flex;
    min-width: 300px;
    min-height: 80px;
    max-width: 300px;
    max-height: 80px;
    /* position: absolute; */
    position: fixed;
    top: 10px;
    right: -300px;
    z-index: 2;
    background-color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    border-radius: 7px;
    opacity: 0.95;
}
